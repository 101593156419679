import { defineStore } from 'pinia'
import { api } from '../helpers/api'

export const useCampaignStore = defineStore('campaign', {
  state: () => ({
    loading: false,
    error: null,
    settings: null,
    establishments: [],
    cities: {},
    neighborhoods: {},
    activeCampaign: null,
    winners: [],
    hasWinners: false
  }),
  actions: {
    async fetchCampaignData() {
      this.loading = true
      this.error = null
      try {
        const response = await api.get('/campaign-settings/1')
        this.settings = response.data.settings[0]
        this.establishments = response.data.establishments

        // Process establishments to create cities and neighborhoods structure
        this.establishments.forEach(establishment => {
          if (!this.cities[establishment.city_id]) {
            this.cities[establishment.city_id] = establishment.city_id
          }
          if (!this.neighborhoods[establishment.city_id]) {
            this.neighborhoods[establishment.city_id] = {}
          }
          if (!this.neighborhoods[establishment.city_id][establishment.neighborhood_id]) {
            this.neighborhoods[establishment.city_id][establishment.neighborhood_id] = establishment.neighborhood_id
          }
        })
      } catch (error) {
        this.error = error.response?.data?.message || 'Erro ao buscar dados da campanha'
      } finally {
        this.loading = false
      }
    },
    getEstablishmentsByNeighborhood(cityId, neighborhoodId) {
      return this.establishments.filter(e => e.city_id === cityId && e.neighborhood_id === neighborhoodId)
    },
    async fetchActiveCampaign() {
      this.loading = true
      this.error = null
      try {
        const response = await api.get('/campaigns/active')
        this.activeCampaign = response.data
        if (this.activeCampaign) {
          await this.fetchWinners()
        }
      } catch (error) {
        this.error = error.response?.data?.message || 'Erro ao buscar campanha ativa'
      } finally {
        this.loading = false
      }
    },
    async fetchWinners() {
      this.loading = true
      this.error = null
      try {
        const response = await api.get('/winners', {
          params: {
            campaign_id: this.activeCampaign?.id
          }
        })
        this.winners = response.data.data
        this.hasWinners = this.winners.length > 0
      } catch (error) {
        this.error = error.response?.data?.message || 'Erro ao buscar vencedores'
      } finally {
        this.loading = false
      }
    },
  }
})